import React from "react"

const Booking = ({ src, id }) => {
  return typeof window !== "undefined" ? (
    <>
      <iframe
        title="Appoiments booking"
        src={`${src}/?noframe=true&skipHeaderFooter=true`}
        id={id}
        style={{
          width: "100%",
          height: "1000px",
          border: 0,
          padding: 0,
          backgroundColor: "transparent",
        }}
        frameborder="0"
        allowtransparency="true"
      ></iframe>

      {window.addEventListener &&
        window.addEventListener(
          "message",
          function (event) {
            if (event.origin === src) {
              document.getElementById(id).style.height = event.data + "px"
            }
          },
          false
        )}
    </>
  ) : null
}

export default Booking
