import React from "react"
import PageLayout from "../layouts/pageLayout"
import Booking from "../components/utils/Booking"
import SEO from "../components/utils/Seo"

const AppoinmentsPage = () => {
  const settings = {
    src: "https://unitechpestcontrol.youcanbook.me",
    id: "ycbmiframeunitechpestcontrol",
  }

  return (
    <PageLayout>
      <SEO
        title="Appoinments"
        description="One of our professional exterminating experts will come to your home or office in the greater St. Louis area, and solve all your bed bug problems at a time that is convenient for you!  Simply choose an available date and time from the calendar below and request a Free Inspection."
      />
      <Booking {...settings} />
    </PageLayout>
  )
}
export default AppoinmentsPage
